/*** spacings ***/
/*** font size css **/
/*flexible width*/
/*flexible height*/
/*setting font size line height and color together*/
/***** image viewer css *****/
#iv-container {
  position: fixed;
  background: #0d0d0d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
}
.iv-container {
  overflow: hidden;
}
.iv-close {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-shadow: 0px 0px 3px #6d6d6d;
  -webkit-transition: all ease 200ms;
  -moz-transition: all ease 200ms;
  -o-transition: all ease 200ms;
  transition: all ease 200ms;
}
.iv-close:after,
.iv-close:before {
  content: '';
  height: 4px;
  width: 32px;
  background: #d1dcdf;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
}
.iv-close:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.iv-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.iv-close:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
/***** snap view css *****/
.iv-snap-view {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 20px;
  left: 20px;
  border: 1px solid #666;
  background: black;
  z-index: 100;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  -o-transition: all ease 400ms;
  transition: all ease 400ms;
  opacity: 0;
}
.iv-snap-image-wrap {
  display: inline-block;
  position: absolute;
  max-width: 150px;
  max-height: 150px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.iv-snap-image {
  position: relative;
}
.iv-snap-handle {
  position: absolute;
  border: 1px solid #ccc;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px 200px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 0px 200px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 200px rgba(0, 0, 0, 0.5);
}
/*** zoom slider ***/
.iv-zoom-slider {
  width: 100%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #666;
  border-top: 0;
  background: rgba(204, 204, 204, 0.1);
  height: 15px;
  position: absolute;
  top: 150px;
  left: -1px;
}
.iv-zoom-handle {
  width: 20px;
  height: 15px;
  background: #ccc;
  position: absolute;
}
/**** snap view css end *****/
.iv-image-view {
  position: absolute;
  height: 100%;
  width: 100%;
}
.iv-image-wrap {
  display: inline-block;
}
.iv-image-wrap:active {
  cursor: move;
}
.iv-large-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background: white;
}
.iv-loader {
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  top: 50%;
  left: 50%;
  z-index: 100;
}
/****** CSS loader by http://projects.lukehaas.me/css-loaders/ *****/
.iv-loader {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 100;
  margin-top: -16px;
  margin-left: -16px;
  font-size: 5px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.4) !important;
  border-right: 1.1em solid rgba(0, 0, 0, 0.4) !important;
  border-bottom: 1.1em solid rgb(254, 202, 55) !important;
  border-left: 1.1em solid #0176bd !important;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.iv-fullscreen {
  position: fixed;
  background: #0d0d0d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
}
.iv-fullscreen-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.iv-fullscreen-close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-shadow: 0px 0px 3px #6d6d6d;
  transition: all ease 200ms;
}
.iv-fullscreen-close:after,
.iv-fullscreen-close:before {
  content: '';
  height: 4px;
  width: 24px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.iv-fullscreen-close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.iv-fullscreen-close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.iv-fullscreen-close:hover {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
.iv-loader:after {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/** loader css end **/
@media screen and (max-width: 767px) {
  .iv-snap-view {
    z-index: -1;
    visibility: hidden;
  }
}
/****** demo and page css ******/
#iv-container {
  z-index: 10000;
}
#image-gallery-1 {
  max-width: 800px;
}
#image-gallery-1 .gallery-items {
  float: left;
  height: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
/** Gallery 2 css **/
.image-gallery-2 {
  width: 100%;
  position: relative;
  height: 700px;
  background: #000;
}
.image-gallery-2 .image-container {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e2e2;
  border: 0.1px solid rgba(0, 0, 0, 0.3);
}
.image-gallery-2 .prev,
.image-gallery-2 .next {
  position: absolute;
  height: 32px;
  margin-top: -66px;
  top: 50%;
  font-size: xx-large;
  color: #757575;
}
.image-gallery-2 .prev {
  cursor: pointer;
  left: 20px;
  transition: linear 0.3s;
  &:hover {
    left: 25px;
    transition: linear 0.3s;
  }
}
.image-gallery-2 .next {
  right: 20px;
  cursor: pointer;
  transition: linear 0.3s;
  &:hover {
    right: 25px;
    transition: linear 0.3s;
  }
}
.image-gallery-2 .footer-info {
  position: absolute;
  height: 50px;
  width: 100%;
  left: 0;
  bottom: 0;
  line-height: 50px;
  font-size: 24px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
}
/** Image gallery 3 **/
.pannable-image {
  width: 700px;
}

.footer-icon {
  margin-top: 7px;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  color: white;

  &:hover {
    color: #feca37 !important;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
  }
}

.inline-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  position: relative;
  float: right;
  width: 100%;
}

.inline-pdf {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 93%;
  width: 88%;
  margin-left: 6%;
  margin-right: 6%;
}

.pdf-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.pdf-button-active {
  width: 258px;
  text-align: center;
  height: 57px;
  line-height: 56px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;

  &:hover {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
  }
}

.filterTooltip {
  display: flex;
  justify-content: space-around;
  width: 160px !important;
  right: 10% !important;
  left: 0% !important;

  span {
    margin-left: 10px;
  }

  i {
    margin-right: 10px;
    font-size: 20px;
  }
}
