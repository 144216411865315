@import './assets/styles/_vars.scss';
@import '../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
//@import './assets/styles/_custom-breakpoints.scss';

* {
  outline: none !important;
}

body {
  margin: 0;
}

.mw-n {
  max-width: none;
}

.pointer {
  cursor: pointer;
}

.list-menu > a > .mdc-list-item__content {
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0px;
}

.font-size-16 {
  font-size: 16px;
}

nome-label-designer {
  .mat-button-toggle-group,
  .mat-button-toggle-standalone {
    box-shadow: none !important;
  }
}

.page-section-title {
  font-weight: 600;
  font-size: 16px;
}

.evidences-dialog {
  max-height: 650px;
  height: 80vh;
  width: 60vw;
  max-width: 400px !important;
  .type-field {
    .mat-form-field-wrapper {
      padding-bottom: 0.5em;
    }
    .mat-form-field-underline {
      bottom: 0.5em !important;
    }
  }
  .value-field {
    .mat-form-field-infix {
      padding-top: 0px;
    }
    .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding-top: 0px;
    }
  }
  .mdc-dialog__surface {
    overflow: hidden;
  }
  .mat-mdc-dialog-content {
    height: calc(100% - 55px) !important;
    max-height: none;
  }
  .vsc-controller {
    display: none !important;
  }
}

.details-page-title-text {
  height: 100%;
  line-height: 38px;
  font-size: 20px;
  word-break: break-word;
  font-weight: bold;
}

.label_sizes_menu > .mat-mdc-menu-content {
  overflow-x: hidden;
}
.label_sizes_menu {
  max-width: 300px !important;
}
.new-label-dialog {
  position: absolute !important;
  top: 100px;
}

.mat-bottom-sheet-container,
.media-builder-sheet {
  height: 90vh !important;
  max-height: 90vh !important;
  //width: 50vw !important;
  overflow: unset !important;
}

.mat-bottom-sheet-container,
.menu-builder-sheet {
  height: 90vh !important;
  max-height: 90vh !important;
  //width: 50vw !important;
  overflow: unset !important;
}

.item-selection-sheet {
  height: 832px !important;
  max-height: 832px !important;
  overflow: unset !important;
}

.menu-builder-sheet-no-recap {
  .mat-bottom-sheet-container {
    height: 85vh !important;
    max-height: 85vh !important;
    //width: 50vw !important;
    overflow: unset !important;
  }
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.recipe-profiles-sheet {
  height: 97vh !important;
  max-height: 97vh !important;
  width: 80vw !important;
  overflow: unset !important;
}

.new-country-profile-sheet {
  height: 80vh !important;
  max-height: 80vh !important;
  width: 50vw !important;
  overflow: unset !important;
}

:not(.right-side-navigator) > .mat-drawer-inner-container {
  overflow: hidden !important;
}

.edit-category-details > mat-form-field {
  width: 100%;
}
.folderclass {
  font-size: 35px;
}
.folder > .mat-mdc-card-header-text {
  margin: 0 0 0 0 !important;
}

.designer-buttons > mat-button-toggle .mat-button-toggle-button > .mat-button-toggle-label-content {
  line-height: 36px !important;
}

.designer-buttons > mat-button-toggle-group > mat-button-toggle .mat-button-toggle-button > .mat-button-toggle-label-content {
  line-height: 36px !important;
}

.designer-buttons > .mat-button-toggle-appearance-standard,
.designer-buttons > mat-button-toggle-group > .mat-button-toggle-appearance-standard {
  background: transparent !important;
}

.toggle-button-container > mat-button-toggle .mat-button-toggle-button > .mat-button-toggle-label-content {
  line-height: 24px !important;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.top-25 {
  top: 25px;
}

.list-item > .dd-list > li:last-child {
  margin-bottom: 30px;
}

.dropdown-clickable-text {
  @extend .clickable-text;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.clickable-text {
  padding: 0px !important;
  border-radius: unset !important;
  min-width: unset !important;
  font-weight: 400 !important;
  line-height: unset !important;
  .mat-mdc-button-ripple {
    background-color: transparent !important;
  }
}

.color-showcase {
  width: 10px;
  height: 10px;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}
nome-details-panel > mat-card > mat-card-header > .mat-mdc-card-header-text {
  margin-left: 0px !important;
}

.disabled {
  //background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26) !important;
  pointer-events: none;
  cursor: default;
}

.section-title {
  font-weight: bolder;
}

.title-name {
  font-size: 18px !important;
  color: $dark-black;
  width: 100%;
}

.mini-fab {
  width: 26px !important;
  height: 26px !important;
}
.mini-fab {
  mat-icon {
    font-size: 16px !important;
    font-weight: bolder;
    margin-top: 8px;
  }
}

.large-fab {
  width: 40px !important;
  height: 40px !important;
}

.large-fab-disabled {
  width: 40px !important;
  height: 40px !important;
  background-color: var(--muted-grey) !important;
}

.medium-fab {
  width: 33px !important;
  height: 33px !important;
}
.medium-fab > span > mat-icon {
  font-size: 25px !important;
  font-weight: bolder;
  margin-top: -9px;
  margin-left: -1px;
}

.no-shadow {
  box-shadow: none !important;
}

.icon-size {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

#location.mat-option {
  cursor: none;
  align-items: normal;
}

.text-muted {
  color: $color-text-dark-secondary !important;
}

#clear-location {
  background-color: $white;
}
.table-hover tbody tr:hover {
  background-color: transparent;
}
// .table-hover>tbody>tr:hover>td, .table-hover>tbody>tr:hover>th {
//     background-color: var(--light-grey);

//   }

.mat-select-panel .mat-option:hover {
  background-color: var(--light-grey);
}

.mat-select-panel {
  max-height: 50vh !important;
  &.max-h-550px {
    max-height: 550px !important;
  }
  &.max-h-100vh {
    max-height: 100vh !important;
  }
  &.flex-direction-column {
    display: flex;
    flex-direction: column;
  }
  &.fz-15px {
    font-size: 15px !important;
  }
  &.colored-border-active {
    border: 2px solid;
    border-color: #de6e37;
  }
  &.border-radius-5px {
    border-radius: 5px;
  }
  &.center-dropdown-with-search-panel {
    width: 234px !important;
    min-width: 100% !important;
    margin-top: 30px !important;
    transform: translateX(15px) !important;
    .input-wrapper {
      width: 85% !important;
    }
  }
  &.center-nome-mat-select-panel {
    width: 230px !important;
    min-width: 100% !important;
    margin-top: 30px !important;
    transform: translateX(40px) translateY(0px) !important;
  }
  &.custom-scrollbar,
  &.custom-scrollbar cdk-virtual-scroll-viewport {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #00000000;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #999999;
      border-radius: 4px;
    }
  }
}

html,
body {
  overflow: hidden;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  display: inline-block;
  border-radius: $border-radius;
  padding: 7px;
  text-align: center;
  height: 34px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.inputfile:not([disabled]) + label {
  cursor: pointer;
}

.inputfile[disabled] + label {
  @extend .disabled;
  background-color: $disabled-background;
}

.new-brand-dialog {
  max-width: 900px;
  height: 97vh;
  height: 730px;
  width: 65vw;
}

.cookie-consent-dialog {
  width: 384px;
}

@media only screen and (max-width: $ipad-breakpoint-portrait) {
  .inputfile + label {
    height: auto;
  }
}

.thumb-hint {
  color: $color-text-dark-secondary;
  font-style: italic;
}

.red-background {
  background-color: $offline-red !important;
}

.red-color {
  color: $offline-red !important;
}

.mid-bottom-sheet {
  max-width: 550px !important;
  min-width: 550px !important;
}

.top-buttons-section {
  margin: 0px;
  justify-content: flex-end !important;
  height: 0px;
  overflow: visible;
  align-items: flex-end;
}

.top-buttons-section .section-button {
  position: relative !important;
  top: -40px;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.workflow-duration-fields .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0px !important;
  border-top-width: 0px !important;
  padding-bottom: 1px !important;
}
.workflow-duration-fields {
  display: flex;
  flex-direction: column;
}
.node-card-title-multiline {
  text-overflow: ellipsis;
  height: 50px;
  overflow: hidden;
  margin-bottom: 0px;
}
.backdrop {
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.32) !important;
}
.noPointerEvent {
  pointer-events: none;
}

@supports (display: -webkit-box) {
  .node-card-title-multiline {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}

@media only screen and (max-width: $ipad-breakpoint-landscape) {
  .details-panel-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    z-index: 9991;
    flex: 0;
    display: block;
    margin: 0;
    padding: 0px 15px;
  }
  .details-panel-wrapper .details-panel-container {
    position: relative;
    top: 60px;
    height: calc(100% - 60px) !important;
    overflow-y: auto;
    width: calc(100% - 50px);
    left: 50px;
  }
  // When side tree is opened in 'side' mode
  .main-content-margin .details-panel-wrapper .details-panel-container {
    left: 311px !important;
    width: calc(100% - 311px);
  }

  .overflow-md-scroll {
    overflow: scroll !important;
  }
}
.temp-checklist-dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.location-manager-circle-bg-color {
  background-color: var(--location-manager-circle-bg-color);
}

.location-manager-icon-color {
  color: var(--location-manager-circle-bg-color);
}

.subtitle-color {
  color: var(--subtitle-color);
}

.alt-subtitle-color {
  color: var(--alt-subtitle-color);
}

.details-panel-container {
  height: 100%;
}

.dark-grey {
  background-color: var(--paginator-bg-color);
}
.categories-folder {
  height: 100%;
}

.locations-tree-bold > .mat-select-trigger {
  font-weight: bold !important;
  font-size: 22px;
}

.item-type-tree-bold > .mat-select-trigger {
  font-weight: bold !important;
  font-size: 16px !important;
}

.resellers-tree-bold {
  font-weight: bold !important;
}

.detail-type-bold > .mat-select-trigger {
  font-weight: bold !important;
  font-size: 18px;
}

.white-background {
  background-color: $white !important;
}

.main-button {
  width: auto;
  height: 35px !important;
  @extend .main-button-style;
}

.main-button-style {
  font-size: 17px !important;
  letter-spacing: 1px !important;
  font-weight: 400 !important;
}

.button-dimensions {
  line-height: 30px !important;
  min-width: 90px !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 22px;
}
.new-menu {
  max-width: 600px !important;
  min-width: 400px !important;
}

.dialog-title {
  text-align: center;
}

.dialog-close-btn {
  margin-top: 4px;
}

.dialog-sub-title {
  color: $color-text-dark-secondary;
  // font-style: italic;
  text-align: center;
}

.locations-brands-selector {
  display: inline-block;
  margin-left: 20px;
  width: 12em;
}
.locations-brands-selector > mat-form-field {
  display: block;
  width: 100%;
}
.full-screen-dialog-panel {
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  pointer-events: none !important; // enables dismiss on click
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-bottom: 0.2em !important;
  padding-top: 0.8em !important;
}
.add-style-dialog {
  width: 40vw;
  height: 60vh;
  max-height: 450px;
}

.printer-dialog {
  width: 50vw;
  max-height: 97vh !important;
  overflow: unset !important;
}

.scale-container-dialog {
  width: 900px;
  max-height: 97vh !important;
  overflow: unset !important;
}

.custom-font-quick-filter {
  font-size: 14px;
  font-weight: bold;
}

.hidden {
  display: none !important;
}

.date-settings-dialog {
  width: 30vw;
}

.brand-dialog {
  width: 65% !important;
}

.new-account-dialog {
  width: 80vw !important;
  max-height: 97vh !important;
  overflow: unset !important;
}

.inner-padding-35 .mdc-dialog__surface {
  padding-left: 35px;
  padding-right: 38px;
}

.new-reseller-dialog {
  max-height: 97vh !important;
  max-width: 70vw !important;
  width: 70vw;
  margin-left: 10vh;
  margin-right: 10vh;
  overflow: unset !important;
}

.nome-html .note-editable {
  height: 100% !important;
}
.nome-html .note-editor.note-frame {
  max-height: 380px !important;
}

.w-90 {
  width: 90% !important;
}

.w-10 {
  width: 10% !important;
}

.not-visible {
  visibility: hidden;
}

.assign-terminal-dialog {
  // height: 650px;
  max-width: 600px;
  min-width: 400px;
}

.custom-clustericon {
  background: var(--cluster-color);
  color: $white;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

.chart-legend .legend-wrap {
  width: 100% !important;
}

.owl-dt-control-content,
.owl-dt-weekday,
.owl-dt-calendar-cell-content {
  text-transform: capitalize;
}

.reports-title {
  .mat-mdc-card-header-text {
    width: 100%;
    margin: 0px;
  }
  h5:not(.w-100-p) {
    width: calc(100% - 100px);
  }
  h6 {
    width: calc(100% - 100px);
  }
  .date-title {
    color: #eb6e2a;
  }
  .sub-title {
    color: $color-text-light-secondary;
  }
}

.export-button {
  &[disabled] {
    opacity: 0.5;
  }
  img,
  mat-icon {
    width: 40px;
    height: 40px;
  }
}

.update-app-dialog {
  width: 25vw;
}
.redTooltip {
  background-color: red;
}

.add-temp-checklist {
  width: 550px;
}

.timer-dialog {
  width: 550px;
}

.bottom-button {
  position: absolute;
  bottom: 24%;
  right: 33%;
}

.max-w-700px {
  width: 100%;
  max-width: 700px !important;
}

.dialog-max-w-500px {
  width: 100%;
  max-width: 500px !important;
}

.download-pdf-popup {
  min-height: 120px;
  .mdc-dialog__surface {
    padding: 10px !important;
    overflow: visible;
  }
}

.add-new-temp-task {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
  .mdc-dialog__surface {
    padding: 0 !important;
  }
}
.flex-1 {
  flex: 1;
}

.arrow_left {
  width: 16px !important;
  height: 16px !important;
  svg {
    margin-bottom: 13px;
    margin-left: 5px;
  }
}

.joyride-step__container {
  border-radius: 5px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
  max-height: 100vh;
  overflow: auto;
}
.joyride-content-wrapper {
  max-width: 240px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.joyride-content-wrapper.joyride-content-wrapper-wide {
  max-width: 800px;
}

.joyride-step__body > h2 {
  font-size: 24px;
}

.black-background {
  background-color: black;
}

.add-report-contacts-dialog {
  min-width: 600px;
  min-height: 50vh;
}

.add-inventory-contacts-dialog {
  min-width: 600px;
}

.custom-chip-dialog {
  width: 50vw;
  height: 40vh;
}

mat-radio-button {
  user-select: none;
  label {
    margin-bottom: 0;
  }
}

mat-option.hide-checkbox mat-pseudo-checkbox {
  display: none;
}

nome-custom-image-viewer {
  width: 100% !important;
  max-width: 500px !important;
  margin: 0 auto !important;
  height: 100%;
  .image-gallery-2 {
    height: 100%;
    background: transparent !important;
    .footer-info,
    .next,
    .prev {
      display: none !important;
    }
  }
}

.multiple-lines-ellipsis {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}

mat-slide-toggle {
  label {
    margin-bottom: 0;
  }
}

.note-modal-backdrop {
  z-index: 999 !important;
}

.note-color .note-btn::after {
  display: none;
}

.note-modal-footer {
  height: auto !important;
  text-align: right !important;
  .note-btn {
    float: none !important;
  }
  .note-btn.disabled {
    background-color: lightgray !important;
    border-color: darkgray !important;
  }
}

.note-modal-body {
  .checkbox {
    input {
      margin-right: 5px !important;
    }
  }
}

.note-editor .dropdown-toggle::after {
  display: none;
}

.cdk-overlay-pane.pb-0 mat-dialog-container {
  padding-bottom: 0;
}

.cdk-overlay-pane.add-label-size-dialog {
  overflow-y: auto;
}

// bootstrap tooltip styles
bs-tooltip-container {
  .tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    background-color: #707070;
  }
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #707070 !important;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #707070 !important;
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top .arrow::before {
  border-top-color: #707070 !important;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #707070 !important;
}

.mat-mdc-list-base {
  padding: 0 !important;
}

.pe-none {
  pointer-events: none;
}

.mapModalWrapper {
  width: 70% !important;
  height: 90% !important;
  .custom-icon-1 {
    --cluster-color: #e55239;
  }
  .custom-icon-2 {
    --cluster-color: #e2d434;
  }
  .custom-icon-3 {
    --cluster-color: #4db193;
  }
  .dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .mat-mdc-dialog-content {
      flex: 1;
    }
  }
}

header.dialog-header {
  position: relative;
  margin-bottom: 30px;
  padding-right: 24px;
  .dialog-title {
    margin-bottom: 0;
  }
  mat-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.inputModalWrapper {
  width: 50em !important;
  .mdc-dialog__surface {
    padding-top: 1em;
  }
}

.temperatureModalWrapper {
  width: 21em !important;
  .mdc-dialog__surface {
    padding-top: 1em;
  }
}

.bulk-media-dialog {
  width: 800px;
}

.move-location-dialog {
  .mdc-dialog__surface {
    overflow: hidden;
  }
}

.reports-dialog-fullscreen {
  width: calc(100% - 50px) !important;
  margin-left: 50px !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.line-height-normal {
  line-height: normal !important;
}

.confirmation-dialog-first-sentence-mb-0 nome-confirmation .mat-mdc-dialog-content > p:first-child {
  margin-bottom: 0;
}

.mat-mdc-menu-panel,
.mat-menu-panel-max-width-none {
  max-width: none !important;
}

.mat-menu-panel-p-y-0 .mat-mdc-menu-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.black-color-forced {
  color: black !important;
}

.export-icon {
  width: 35px !important;
  height: 35px !important;
}

.vertically-responsive-dialog {
  max-height: 100vh !important;
}

mat-option nome-tree .content {
  max-height: $dropdown-tree-height;
}

.clear-button-inside-dropdown {
  height: 2em !important;
  line-height: 2em !important;
}

.additional-info-dialog {
  max-width: 900px;
  height: 75vh;
  height: 530px;
  width: 65vw;
}

//google map
.map-legend {
  background: $white;
  right: 10px !important;
  padding: 6px;
  border-radius: 4px;
  box-shadow: -1px 0 2px 0 #cccccc;
  -webkit-box-shadow: -1px 0 2px 0 #cccccc;
  user-select: none;
}

.map-legend div.legend-row {
  vertical-align: middle;
  margin: 5px 0px;
}

.map-legend div.legend-row span {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 7px;
  background: var(--cluster-color);
}

.map-legend div.legend-row p {
  margin: 2px 0px 0px 10px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: 500;
  color: $middle-grey;
}

.fz-75perc {
  font-size: 75%;
}

mat-icon {
  overflow: inherit !important;
}

.width-fit-content {
  width: fit-content;
}

// used when the mat error is outside a form field
.absolute-mat-error {
  font-size: 75%;
  position: absolute;
  top: 100%;
  margin-top: 6.5px;
}

mat-form-field.asterisk-hidden .mat-form-field-required-marker {
  display: none;
}
.full-width {
  width: 100%;
}
.custom-editor-style .note-editable {
  min-height: 75px;
}
:root {
  --sidenav-width: 260px;
}

.pointer-events-auto {
  pointer-events: auto;
}

.mat-mdc-button-ripple {
  background-color: transparent !important;
}

.warning-box {
  border-radius: 5px;
  padding: 3px 8px;
  margin-bottom: 15px;
}

mat-option.show-error-message {
  overflow: visible;
  .mat-option-text {
    overflow: visible;
  }
  mat-error {
    white-space: normal;
  }
}

mat-checkbox.label-mb-0 label {
  margin-bottom: 0;
}

.selectable-date {
  font-weight: bold;
  font-size: 15px;
}

.non-selectable-date {
  cursor: not-allowed !important;
  span {
    text-decoration-line: line-through !important;
  }
}

.remove-bg-and-color:not([disabled]) {
  background: none !important;
  color: unset !important;
}

mat-icon[data-mat-icon-name='search'] {
  height: 12px !important;
  width: 12px !important;
  display: flex;
}

.mdc-tab--active {
  .custom-nav-link-text,
  .mdc-tab__text-label {
    font-weight: bold;
  }
}

a[mat-tab-link] {
  text-decoration: none;
}

.modified-row-border-left-color {
  border-color: var(--modified-row-color) !important;
}

.mat-mdc-card:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
  border: 1px solid #ddd;
}

.mat-form-field-type-mat-select .mat-form-field-label {
  width: calc(100% - 25px) !important;
}

.location-container mat-select-trigger {
  font-size: 16px;
}

/*
  in angular material version 14 (legacy) the mat-dialog-container had padding
  and all other elements (mat-dialog-title/content/actions) did not

  in version 16, the container does not have padding, but the inner elements have

  since we don't always use the inner elements in dialogs, I had to update the styles
  to fix the paddings.
*/
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;

  .mat-mdc-dialog-title {
    padding-bottom: 0;
    margin-bottom: 20px;
    &::before {
      height: auto;
    }
  }

  .mat-mdc-dialog-title,
  .mat-mdc-dialog-actions {
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-dialog-content {
    // this is used to prevent pushing the scrollbar to the left
    margin: 0 -24px;
    padding: 0 24px;
  }

  .mat-mdc-dialog-actions {
    min-height: auto;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 20px;
  }
}

mat-icon[svgicon='edit'] {
  width: 16px;
  height: 16px;
  line-height: 1 !important;
}

mat-form-field.move-error-up-a-bit .mat-form-field-subscript-wrapper {
  margin-top: 0.25em;
}

.mat-mdc-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: rgba(0, 0, 0, 0) !important;
}

.mdc-button {
  display: inline-block !important;
  vertical-align: baseline !important;
  --mdc-typography-button-letter-spacing: normal;
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 100% !important;
}

.mat-fab-flex-container-end {
  display: flex;
  align-items: end;
}

.mat-fab-flex-container-center {
  display: flex;
  align-items: center;
}

.mat-button-with-icon {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

mat-checkbox {
  label {
    margin-bottom: 0;
  }

  // checkbox without label (table checkboxes)
  .mdc-form-field:has(label:empty) {
    width: 18px;
    height: 18px;
    justify-content: center;
  }

  .mdc-label {
    white-space: nowrap;
  }
}

.fz-16px {
  font-size: 16px;
}

mat-selection-list {
  mat-list-option {
    height: 48px !important;
    padding-left: 5px !important;
  }
  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-left: 0;
    margin-right: 0;
  }
  .mdc-list-item__content {
    position: relative;
    padding-left: 4px !important;
  }
}

/*
  in Angular material v14 the below didn't have padding
  when we migrated to v16, the below got padding
  so to have an easier migration, we removed the padding from the below
*/
mat-card-content,
mat-card-header,
mat-card-actions {
  padding: 0 !important;
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  font-size: 14px;
}

.mat-mdc-card {
  padding: 16px;
}

.mat-mdc-card-avatar {
  margin-bottom: 0 !important;
}

.mat-mdc-radio-button .mdc-form-field {
  font-size: 16px !important;
}

.mat-mdc-slide-toggle {
  .mdc-label {
    --mat-slide-toggle-label-text-size: 16px;
    white-space: nowrap;
  }
  .mdc-form-field.mdc-form-field--align-end > label {
    padding-right: 8px !important;
  }
  .mdc-form-field:not(.mdc-form-field--align-end) > label {
    padding-left: 8px !important;
  }
  .mdc-switch__icons {
    display: none;
  }
}

mat-password-strength {
  .mat-mdc-progress-bar {
    .mdc-linear-progress__buffer-bar {
      --mdc-linear-progress-track-color: #e6e5e6; // the track color
      border-radius: 2px;
    }

    &.mat-warn {
      .mdc-linear-progress__bar-inner {
        --mdc-linear-progress-active-indicator-color: #ff0000;
      }
    }

    &.mat-accent {
      .mdc-linear-progress__bar-inner {
        --mdc-linear-progress-active-indicator-color: #f26621;
      }
    }

    .mdc-linear-progress__bar-inner {
      --mdc-linear-progress-active-indicator-color: #8ed303;
      border-radius: 2px;
    }
  }
}

ngx-timepicker-period-selector {
  .period-selector {
    background-color: white !important;
  }
  .period-selector__button--active {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .period-selector__button:not(.period-selector__button--active):hover {
    background-color: #0000000a;
  }
}
