// here are the variables shared by all brands
$disabled: rgba(0, 0, 0, 0.38);
$disabled-background: rgba(0, 0, 0, 0.12);

//mostly for the table/card hover color or backgrounds
$white-light-grey: #f2f2f2;
$medium-light-grey: #dee6ed;
//used of grey icons (info icon...)
$middle-grey: #747474;
//used for disbled icons
$light-middle-grey: #bdbdbd;
//used for tab titles
$new-grey: #778598;
$medium-dark-grey: #223344;
$mat-list-option-disabled-grey: #eeeeee;

// common for card subtitles and labels
$color-text-dark-primary: $medium-dark-grey;
// common for lighter (grey) card subtitles and labels
$color-text-dark-secondary: #54657e;
// to imitate material form default labels/components color
$color-text-light-primary: $middle-grey;
//if a lighter grey is needed for text
$color-text-light-secondary: #a8a9ab;
//light disabled text
$color-text-light-tertiary: #cfd9e3;
//light borders
$color-border-light-primary: #e0e0e0;

// font text
$font-text-small-primary: 14px;
$font-text-small-secondary: 11px;
$font-text-large-primary: 18px;

// border radius
$border-radius-small-primary: 3px;
$border-width-small-primary: 1px;
$border-radius: 8px;
$min-drawer-width: 50px;
$max-drawer-width: 347px;
$new-label-dialog-field-width: 80%;
$common-transition: all 0.1s;

//Tree colors
$dark: #2e3640;
//always use this black color for black text
$dark-black: #1b1c1e;
$title_dark: #2c303a;

//Unassign button
$default_button_color: #6c757d;
$see_more_button_font_size: 25px;
$white: #ffffff;
// used for status icons, background shapes
$offline_red: #ff3300;
//used for error and texts (it's less flashy than the first)
$offline_red_alt: #f44335;
$highlight_yellow: #ffd033;
$light-blue: #dce8fd;
//used for actual links color
$link-blue: #007bff;
$color-button-export-csv: #217346;
$ipad-breakpoint-portrait: 768px;
$ipad-breakpoint-landscape: 1025px;
$created-at-location-color: #1d5dcb;
//legend color for the pending status in orders page
$inventory-pending-blue: #0066cf;
//legend color for the drafted status in orders page
$drafted-grey: #54657e;
$menu-subscription-orange: #f7a11a;
$menu-inherited-not-assigned: #008fa6;
$menu-unassigned-color: #9024e0;
$table-cell: #000000;
$label-designer-margin-color: red;
$chip-bg: #dee5eb;
$red-legend: #e55239;
$yellow-legend: #e2d434;
$green-legend: #4db193;
$rgb-grey: rgba(0, 0, 0, 0.87);
$label-button-separator-color: #c0ccd9;
body {
  --red-legend: #{$red-legend};
  --yellow-legend: #{$yellow-legend};
  --green-legend: #{$green-legend};
  --rgb-grey: #{rgba(0, 0, 0, 0.87)};
  --approved-green: #4aa22e;
  --rejected-red: #e55239;
  --pending-yellow: #f7a11a;
  --warning-yellow: #ffc925;
  --red-tooltip: #f22222;
}
#thermalLabelWebEditor {
  --shrink-to-fit-icon: var(--shrink-to-fit-icon-path);
  --workspace-background-color: none;
  --label-document-frame-background-image: none;
  --label-document-frame-background-color: #fff;
  --label-document-frame-border-color: #000;
  --label-document-frame-corner-radius: 10px;
  --grid-color: #cecece;
  --item-design-time-border-color: #d3d3d364;
  --item-tooltip-color: #000000;
  --item-tooltip-background-color: #f5f5f5;
  --item-tooltip-border-color: #808080;
  --adorner-handler-background-color: var(--accent-color);
  --adorner-frame-border-color: #000000;
  --adorner-out-of-label-border-color: #000000;
  --repeater-item-color: #00bfff;
  --rulers-background-color: #ffffff;
  --rulers-corner-background-color: #cccccc;
  --rulers-selection-background-color: #cccccc;
  --rulers-text-color: #000000;
}
#label-container {
  overflow: visible !important;
}
$dropdown-tree-height: 290px;
$sticky-column-shadow-color: #c0ccd9;
